import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131')
];

export const server_loads = [0,2,3,12,13,14,15,16,17,6,7,8,9,10,11];

export const dictionary = {
		"/(landing)": [~18,[2]],
		"/api/events": [93],
		"/auth/error": [94],
		"/(legal)/cookies": [19,[3]],
		"/error": [95],
		"/error/no-js": [96],
		"/(public)/login": [~22,[4]],
		"/(public)/logout": [23,[4]],
		"/onboarding/clinica": [~104,[12]],
		"/onboarding/paciente": [~105,[12]],
		"/onboarding/[teamId]/completo": [~101,[12]],
		"/onboarding/[teamId]/owner": [~102,[12]],
		"/onboarding/[teamId]/plan": [~103,[12]],
		"/onboarding/[teamId]/[professionalId]": [~97,[12]],
		"/onboarding/[teamId]/[professionalId]/end": [~98,[12]],
		"/onboarding/[teamId]/[professionalId]/invoicing": [~99,[12]],
		"/onboarding/[teamId]/[professionalId]/key": [~100,[12]],
		"/paciente": [~106,[13]],
		"/paciente/[patientId]/documents/[docId]": [~107,[13]],
		"/paciente/[patientId]/settings": [~108,[13]],
		"/paddle/pay": [109],
		"/(legal)/politica-privacidad": [20,[3]],
		"/(public)/redirect-home": [~24,[4]],
		"/(public)/registro": [~25,[5]],
		"/(public)/registro/clinica": [~26,[5]],
		"/(public)/registro/paciente": [~27,[5]],
		"/(public)/registro/paciente/[inviteCode]": [~28,[5]],
		"/(public)/registro/paciente/[inviteCode]/completo": [~29,[5]],
		"/(public)/registro/profesional": [~30,[5]],
		"/(public)/registro/profesional/[inviteCode]": [~31,[5]],
		"/(public)/registro/profesional/[inviteCode]/completo": [~32,[5]],
		"/(public)/registro/verificar": [~33,[5]],
		"/(public)/reset-password": [~34,[4]],
		"/sentry-example": [110],
		"/superadmin": [111,[14]],
		"/(legal)/terminos-y-condiciones": [21,[3]],
		"/test/availability": [112,[15]],
		"/test/cal": [113,[15]],
		"/test/docuseal": [~114,[15]],
		"/test/dt": [115,[15]],
		"/test/encryption": [116,[15]],
		"/test/error": [~117,[15]],
		"/test/error/full": [~118,[15]],
		"/test/error/unexpected": [~119,[15]],
		"/test/invites": [120,[15]],
		"/test/invoice-number": [121,[15]],
		"/test/landing": [~122,[15,16]],
		"/test/navigation/a": [123,[15,17]],
		"/test/navigation/b": [124,[15,17]],
		"/test/number": [125,[15]],
		"/test/onboarding": [~126,[15]],
		"/test/onboarding/set": [~127,[15]],
		"/test/redirect/a": [128,[15]],
		"/test/redirect/b": [129,[15]],
		"/test/slots": [130,[15]],
		"/test/supabase": [~131,[15]],
		"/(public)/validate-invite": [35,[4]],
		"/[team]/(public)": [36,[6,7]],
		"/[team]/admin": [~38,[6,8]],
		"/[team]/admin/analitica": [39,[6,8]],
		"/[team]/admin/calendario": [40,[6,8]],
		"/[team]/admin/configuracion": [~41,[6,8]],
		"/[team]/admin/configuracion/documentos": [~42,[6,8]],
		"/[team]/admin/configuracion/documentos/[id]": [~43,[6,8]],
		"/[team]/admin/configuracion/eholo": [~44,[6,8]],
		"/[team]/admin/configuracion/emails": [~45,[6,8]],
		"/[team]/admin/configuracion/emails/[emailType]": [~46,[6,8]],
		"/[team]/admin/configuracion/finance": [~47,[6,8]],
		"/[team]/admin/configuracion/finance/[legalEntityId]": [~48,[6,8]],
		"/[team]/admin/configuracion/import-v2": [~49,[6,8]],
		"/[team]/admin/configuracion/subscriptions": [~50,[6,8]],
		"/[team]/admin/configuracion/subscriptions/[id]/update-seats": [~51,[6,8]],
		"/[team]/admin/equipo": [52,[6,8,9]],
		"/[team]/admin/equipo/invitar": [~54,[6,8,9]],
		"/[team]/admin/equipo/[userId]": [~53,[6,8,9]],
		"/[team]/admin/finances": [~55,[6,8]],
		"/[team]/admin/invalid-subscription": [~56],
		"/[team]/admin/pacientes": [~57,[6,8]],
		"/[team]/admin/pacientes/[patientId]": [~58,[6,8]],
		"/[team]/admin/servicios": [~59,[6,8]],
		"/[team]/admin/sessions": [~60,[6,8]],
		"/[team]/admin/therapies": [~61,[6,8]],
		"/[team]/admin/therapies/new": [~62,[6,8]],
		"/[team]/admin/waitlist": [~63,[6,8]],
		"/[team]/profesional/(dashboard)": [~64,[6,10]],
		"/[team]/profesional/configuracion": [~65,[6,10]],
		"/[team]/profesional/configuracion/encryption": [~66,[6,10]],
		"/[team]/profesional/configuracion/facturacion": [~67,[6,10]],
		"/[team]/profesional/configuracion/import": [68,[6,10]],
		"/[team]/profesional/configuracion/import/eholo": [~69,[6,10]],
		"/[team]/profesional/configuracion/import/google-calendar": [~70,[6,10]],
		"/[team]/profesional/configuracion/import/google-calendar/[calendarId]": [~71,[6,10]],
		"/[team]/profesional/configuracion/import/v2": [~72,[6,10]],
		"/[team]/profesional/finances": [~73,[6,10]],
		"/[team]/profesional/finances/invoices/new": [~75,[6,10]],
		"/[team]/profesional/finances/invoices/[invoiceId]": [~74,[6,10]],
		"/[team]/profesional/invalid-license": [76],
		"/[team]/profesional/notificaciones": [77,[6,10]],
		"/[team]/profesional/sessions": [~78,[6,10]],
		"/[team]/profesional/therapies": [~79,[6,10]],
		"/[team]/profesional/therapies/archived": [~89,[6,10]],
		"/[team]/profesional/therapies/new": [~90,[6,10]],
		"/[team]/profesional/therapies/[therapyId]": [~80,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents": [81,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/send": [~83,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/upload": [~84,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/[documentId]": [~82,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/events": [85,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/notes/[noteId]": [~86,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/settings": [~87,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/work": [~88,[6,10,11]],
		"/[team]/waitlist": [~91,[6]],
		"/[team]/waitlist/success": [92,[6]],
		"/[team]/(public)/[professional]": [37,[6,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';