import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'

if (!dev) {
	Sentry.init({
		dsn: 'https://901221a153d5a9708cdd3732ff96644a@o4507072552304640.ingest.de.sentry.io/4507072558661712',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		// replaysSessionSampleRate: 0.1,
		replaysSessionSampleRate: 0,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [Sentry.replayIntegration()],

		environment: window.location.hostname === 'calimana.com' ? 'production' : 'preview'
	})
}

export function handleError({ error, event, status, message }) {
	if (status >= 500 && status < 600) {
		const errorId = crypto.randomUUID()

		if (!dev) {
			Sentry.captureException(error, {
				extra: { event, errorId, status }
			})
		}

		return {
			message,
			rawErrorMessage:
				error &&
				typeof error === 'object' &&
				'message' in error &&
				typeof error.message === 'string'
					? error?.message
					: undefined,
			errorId
		}
	}
	return { message, rawErrorMessage: null, errorId: '' }
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
// export const handleError = handleErrorWithSentry()
